.body {
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 10px;
  margin-top: 20px;
  box-sizing: border-box;
  justify-content: center;
}

.body img {
  flex: 1;
  height: fit-content;
  width: 100%;
  max-width: 500px;
  min-width: 320px;
}

.body div {
  flex: 1;
  width: 100%;
  /* padding:; */
  min-width: 320px;
}
.gradiant {
  background: rgb(154, 99, 170);
  background: linear-gradient(
    90deg,
    rgba(154, 99, 170, 1) 0%,
    rgba(114, 63, 129, 0.9866071428571429) 83%
  );
}
