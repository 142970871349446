.service-detail-container {
    /* min-height:70vh; */
    width:100%;
    display:flex;
    /* border:5px solid black; */
    align-items:center;
    flex-direction: column;
    box-sizing: border-box;
    margin:0px;
    flex:1;
}


.menu-table{
    width:100%;
    min-width: 320px;
}



.image-container{
    flex:1;
    max-width: 100%;
    min-width: 320px;
    /* border:2px solid red;     */
}