@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primaryBackground: #F8F5F0;
  --primaryText: #7b7a78;
  --white: #ffffff;
  
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Architects;
  src: url(./fonts/ArchitectsDaughter-Regular.ttf);
}

@font-face {
  font-family: Akatab;
  src: url(./fonts/Akatab-Regular.ttf);
}

@font-face {
  font-family: Aldrich;
  src: url(./fonts/Aldrich-Regular.ttf);
}

body{
  font-family:Akatab, sans-serif;
  background-color: var(--primaryBackground);
  overflow-x: hidden;
  width: 100%;
  overflow-x: hidden;
}

.header{
  text-shadow: -5px 0px 6px rgba(0,0,0,0.25);
}

.gradient {
  background-image: linear-gradient(
    30deg,
    hsl(284, 30%, 32%) 0%,
    hsl(286, 29%, 34%) 18%,
    hsl(286deg 35% 35%) 26%,
    hsl(286deg 35% 34%) 33%,
    hsl(286deg 36% 32%) 39%,
    hsl(287deg 36% 31%) 44%,
    hsl(287deg 36% 30%) 50%,
    hsl(287deg 37% 28%) 56%,
    hsl(287deg 37% 27%) 61%,
    hsl(287deg 38% 26%) 67%,
    hsl(287deg 39% 25%) 74%,
    hsl(287deg 39% 23%) 82%,
    hsl(287deg 40% 22%) 100%
  );
}