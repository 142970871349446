.header-container{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
     /* border:1px solid black;  */
    box-sizing: border-box;
    width:100vw;
    /* border: 1px solid black; */
}

/* 
.modal {
    display: none; 
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto;
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
  }
  

  .modal-content {
    background-color: #fefefe;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%,-50%);
    border: 1px solid #888;
    width: 80%;
  }
  #close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  #close:hover,
  #close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  } */